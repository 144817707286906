<template>
  <div class="row portfolio-wrapper row--55 rn-custom-portfolio-wrapper rn-hil-portfolio">
    <v-col lg="4" md="4" sm="6" cols="12" v-for="(content, i) in portfolioContent" :key="i">
      <router-link to="/#" class="portfolio-wrap">
        <div class="item-shadow">
          <img :src="content.src" />
        </div>
        <div class="portfolio" :class="content.bgClassName"
          :style="{ background: 'url(' + content.src + ') no-repeat center center / contain !important' }">
          <div class="content">
            <h4 class="title">{{ content.title }}</h4>
            <span class="desc">{{ content.desc }}</span>
          </div>
          <div class="bgDescription"></div>
        </div>
      </router-link>
    </v-col>
    <!-- Start Single Portfoli -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      portfolioContent: [
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa1.webp"),
          title: "产品图1",
          desc: "浩瀚天穹风格",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa2.webp"),
          title: "产品图2",
          desc: "冰山雪水风格",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa3.webp"),
          title: "产品图3",
          desc: "自然绿茵风格",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa4.png"),
          title: "产品图4",
          desc: "待定描述",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa5.png"),
          title: "产品图5",
          desc: "待定描述",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa6.png"),
          title: "产品图6",
          desc: "待定描述",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa7.jpg"),
          title: "产品图7",
          desc: "待定描述",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa8.png"),
          title: "产品图8",
          desc: "待定描述",
        },
        {
          bgClassName: "colorful-project-bg",
          src: require("../../assets/img/xgm/awa9.png"),
          title: "产品图9",
          desc: "待定描述",
        },

      ],
    };
  },
};
</script>
