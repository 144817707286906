<template>
  <!-- Start Blog Area -->
  <div class="rn-blog-area home-one-blog d-flex bg_color--3" id="blog">
    <div class="rn-blog-left w-50">
      <div class="blog-content d-flex align-items-center h-100">
        <div class="text-left content news-content rn-plr section-ptb-xl">
          <h2 class="section-title">AI教育</h2>
          <div class="blog-wrapper">
            <!-- Start Single Blog -->
            <div v-for="(item, index) in artList" :key="index" class="rn-blog">
              <h2 @mouseover="activetab = index" :class="[activetab === index ? 'active' : '']">
                <router-link :to="'/blog-details?id=' + item.id">{{ item.title }} -
                  <span class="date">{{ convertToYYYYMMDDHHmmss(item.createTime) }}</span></router-link>
              </h2>
              <p>
                {{ item.smartTitle }}
              </p>
            </div>
            <v-pagination style="margin-top:15px" v-model="page" :length="maxPage" :total-visible="5" circle
              @next="next()" @previous="previous()" @input="queryArtList()"></v-pagination>
            <!-- End Single Blog -->
          </div>
        </div>
      </div>
    </div>

    <div class="rn-blog-right w-50 ">
      <v-fade-transition leave-absolute v-for="(item, index) in artList" :key="item.id">
        <div v-if="activetab === index" class="h-full rn-images-bg-wrapper"
          :style="{ backgroundImage: 'url(' + item.mainImgUrl + ')' }"></div>
      </v-fade-transition>
    </div>

    <!-- <div class="rn-blog-right w-50 ">
      <v-fade-transition leave-absolute>
        <div v-if="activetab === 1" class="h-full rn-images-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"></div>
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <div v-if="activetab === 2" class="h-full rn-images-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src2 + ')' }"></div>
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <div v-if="activetab === 3" class="h-full rn-images-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src3 + ')' }"></div>
      </v-fade-transition>
    </div> -->


  </div>
  <!-- End Blog Area -->
</template>

<script>
import api from "../../api/api";
export default {
  data() {
    return {
      el: "#blog",
      activetab: 1,
      src1: require("../../assets/img/news/news-1.jpg"),
      src2: require("../../assets/img/news/news-2.jpg"),
      src3: require("../../assets/img/news/news-3.jpg"),
      artList: [],
      page: 1,
      maxPage: 0,
      pageLength: 2
    };
  },
  mounted() {
    this.queryArtList();
  },
  methods: {
    queryArtList() {
      api.get('/center/articles?pageNum=' + this.page + '&&pageSize=3').then(response => {
        const data = response.data;
        if (data.code == 200) {
          this.artList = data.data.records;
          this.pageLength = data.data.total;
          this.maxPage = data.data.pages;
          console.log(this.artList);
          console.log(this.maxPage);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    next() {
      this.queryArtList();
    },
    previous() {
      this.queryArtList();
    },
    convertToYYYYMMDDHHmmss(dateString) {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date string');
      }
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}/${month}/${day}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.rn-blog-area {
  overflow: hidden;
}
</style>
