<template>
  <!-- Start Awards Area -->
  <div class="rn-award-area d-flex bg_color--3" id="awards">
    <div class="rn-award-left w-50 ">
      <v-fade-transition leave-absolute>
        <div v-if="activetab === 1" class="h-full rn-images-bg-wrapper bg-cover"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"></div>
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <div v-if="activetab === 2" class="h-full rn-images-bg-wrapper bg-cover"
          :style="{ backgroundImage: 'url(' + src2 + ')' }"></div>
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <div v-if="activetab === 3" class="h-full rn-images-bg-wrapper bg-cover"
          :style="{ backgroundImage: 'url(' + src3 + ')' }"></div>
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <div v-if="activetab === 4" class="h-full rn-images-bg-wrapper bg-cover"
          :style="{ backgroundImage: 'url(' + src4 + ')' }"></div>
      </v-fade-transition>
      <!-- <v-fade-transition leave-absolute>
        <div v-if="activetab === 5" class="h-full rn-images-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src2 + ')' }"></div>
      </v-fade-transition> -->
    </div>
    <div class="rn-award-right w-50 d-flex align-items-center">
      <div class="text-left award-content rn-plr section-ptb-xl">
        <div class="content">
          <h2 class="section-title">工作流定制</h2>

          <ul class="tabs rn-award-list">
            <li>
              <a @mouseover="activetab = 1" :class="[activetab === 1 ? 'active' : '']" href="#awards">服装模特工作流
                <span>- 换装模特</span></a>
              服装模特任意切换，丝滑换装
            </li>
            <li>
              <a @mouseover="activetab = 2" :class="[activetab === 2 ? 'active' : '']" href="#awards">电商产品图工作流
                <span>- 风格设计</span></a>
              万种风格百变背景，塑造产品
            </li>
            <li>
              <a @mouseover="activetab = 3" :class="[activetab === 3 ? 'active' : '']" href="#awards">人像写真工作流 <span>-
                  人像摄影</span></a>
              真实人像多样滤镜，AI摄影
            </li>
            <!-- <li>
              <a @mouseover="activetab = 4" :class="[activetab === 4 ? 'active' : '']" href="#"
                target="_blank">专业产品模特工作流 <span>- 9.0版本</span></a>
              任意变换模特和产品
            </li> -->
            <!-- <li>
              <a @mouseover="activetab = 5" :class="[activetab === 5 ? 'active' : '']" href="#"
                target="_blank">专业产品模特工作流 <span>- 9.0版本</span></a>
              任意变换模特和产品
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- End Awards Area -->
</template>

<script>
export default {
  data() {
    return {
      el: "#award",
      activetab: 1,
      src1: require("../../assets/img/xgm/stream1.png"),
      src2: require("../../assets/img/xgm/stream2-new.png"),
      src3: require("../../assets/img/xgm/prd3.png"),
      src4: require("../../assets/img/xgm/stream4.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.rn-award-area {
  overflow: hidden;
}

.award-content ul.rn-award-list li a:focus {
  outline: none;
}

.bg-cover {
  background-size: contain;
}
</style>
