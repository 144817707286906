<template>
  <!-- Start Single Slider -->
  <div>
    <VueSlickCarousel v-bind="settings" class="slider-activation-with-slick rn-slick-dot dot-light mb--0">
      <div class="slider--8 fullscreen d-flex align-center justify-center fullscreen bg_image rfanimation-style--1"
        v-for="(slider, i) in sliderContent" :key="i" :style="{ backgroundImage: 'url(' + slider.imageUrl + ')' }">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="content text-center">
                <h2 class="heading-title" style="">{{ slider.title }}</h2>
                <p class="description">
                  {{ slider.smartTitle }}
                </p>
                <div class="slide-btn mt-10">
                  <router-link class="rf-btn" to="#footer">联系我们</router-link>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import api from '../../api/api';
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliderContent: [
        {
          imageUrl: require("../../assets/img/xgm/home0.png"),
          title: "",
          smartTitle: "",
        },
        {
          imageUrl: require("../../assets/img/xgm/home1.png"),
          title: "AI产品图",
          smartTitle: ` 目前AI已经通过对大量的设计产品的模型的分析和采集，已足以帮助设计师进行初稿定制、提供初期的设计想法和建议能够快速减轻设计师的负担。`,
        },
        {
          imageUrl: require("../../assets/img/xgm/home2.png"),
          title: "AI模特换装图",
          smartTitle: `基于AI大模型的运算，现在我们可以通过对单件衣服的训练，来生成出不同姿势、表情、长相的真人模特，且风格、背景、场景一键AI替换，毫无违和`,
        },

        {
          imageUrl: require("../../assets/img/xgm/home3.png"),
          title: "AI培训",
          smartTitle: `AI可以自动执行一些重复和常规的工作，如文案和脚本的初期撰写、市场行情和公司的内部繁琐数据分析，市场的环境预测和初期的客户服务等。`,
        },
      ],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 20,
      },
    };
  },
  methods: {
    getBanner() {
      api.get('/center/banners').then(response => {
        console.log(response);
        if (response.status == 200) {
          this.sliderContent = response.data.data;
        }
      });
    }
  },
  created() {
    this.getBanner();
  }
};
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
</style>
